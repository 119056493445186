import styled from "styled-components";

export const AnnouncementBannerContainer = styled.div`  
  font-family: 'Roboto';
  font-style: normal;
  color: white;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 96px 0;
  ${props => props.background && `
    background: url(${props.background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  `}

  @media (max-width: 992px) {
    padding: 64px 0;
    min-height: 1100px;
  }
  @media (max-width: 768px) {
    padding: 40px 0;
  }
`

export const ImgWrapper = styled.div`
  position: absolute;
  width: 100vw;
  display: flex;
  justify-content: center;
  right: 600px;
  top: -200px;
  @media (max-width: 992px) {
    right: 0;
    top: 420px;
  }
`

export const Hexagon = styled.div`
  overflow: hidden;
  display: inline-block;
  margin: 6em 0;
  min-width: 1200px;
  height: calc(1200px * 0.864);
  transform: rotate(-30deg) skewX(30deg);
  border-radius: 40px;
  *, 
  *:before {
    display: block;
    overflow: hidden;
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
  .inner {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    cursor: pointer;
  }
  .outer:before,
  .outer {
    transform: skewX(-30deg) rotate(60deg) skewX(30deg);
    content: "";
  }
  .outer:before {
    height: 1200px;
    margin-top: -160px;
    transform: skewX(-30deg) rotate(-90deg);
    ${props => `background: url(${props.image}) 1400px;`}
    background-size: cover;
    content: "";
  }
`

export const Content = styled.div`
  position: relative;
  left: 50%;
  width: 45%;
  max-width: 570px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    left: 0;
    width: 100%;
    align-items: center;
    margin: 0 auto;
    padding: 0 15px;
    text-align: center;
  }
`

export const Kicker = styled.p`
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 32px;
`

export const Heading = styled.h2`
  font-weight: 900;
  font-size: 70px;
  line-height: 80px;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    font-size: 55px;
    line-height: 65px;
  }
  @media (max-width: 576px) {
    font-size: 48px;
    line-height: 54px;
  }
`

export const Subheading = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 32px;
  @media (max-width: 576px) {
    font-size: 24px;
    line-height: 28px;
  }
`