import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { 
  AnnouncementBannerContainer, 
  ImgWrapper, 
  Hexagon, 
  Content,
  Kicker,
  Heading,
  Subheading,
} from "./styles/AnnouncementBanner.styled";
import AnnouncementBannerImg from "./AnnouncementBanner-minified.png";
import ButtonWrapper from "./ConversionPanelBtnWrapper";

const AnnouncementBanner = ({ component }) => {
  const hasImage = component?.reference?.__typename === "ContentfulComponentImage" 
  const image = component?.reference?.primaryImage
  const kicker = component?.kicker
  const heading = component?.heading 
  const subheading = component?.subheading?.raw
  const CTAs = component?.callsToAction
  const background = component?.background 
  const backgroundImage = AnnouncementBannerImg

  return (
    <AnnouncementBannerContainer background={backgroundImage}>
      {hasImage && 
        <ImgWrapper>
        {/* TODO: confirm with Adam how to approach converting this img to gatsby image */}
          <Hexagon image={image?.file?.url}>
            <div className="inner">
              <div className="outer"/>
            </div>
          </Hexagon>
        </ImgWrapper>
      }
      <Content>
        {kicker && 
          <Kicker>{kicker}</Kicker>
        }
        {heading && 
          <Heading>{heading}</Heading>
        }
        {subheading && 
          <Subheading>{documentToReactComponents(JSON.parse(subheading))}</Subheading>
        }
        {CTAs && CTAs.map((cta, index) => (
          <ButtonWrapper key={index} cta={cta} background={background}/>
        ))}
      </Content>
    </AnnouncementBannerContainer>
  )
}

AnnouncementBanner.displayName='AnnouncementBanner'

export default AnnouncementBanner
